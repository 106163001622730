/* --------------------*/
/* ---  Interface  --- */
/* --------------------*/

/* Calculator Colors */
$grayBackgroundContainer: #f6f6f6;
$grayBrikss: #e7eaed;
/* Sizes */
$marginBlock: 1.5rem;
$marginBlockSection: 5rem;
$marginInlineMin: 1rem;
$paddingContainerMin: 1rem;
$doublePaddingContainerMin: 2rem;
$paddingRows: .5rem 1rem;

// CalculatorVars
$fontSizeXL: 3rem;
$fontSizeM: 2.5rem;
$fontSizeS: 1.8rem;
$fontSizeXs: 1.3rem;
$fontSizeXxs: 1.1rem;

/* Rounded and Borders */
$borderRounded: 0.5rem;

/* ---  Project Status --- */
$project-disponible-light: #9eeec3;
$project-disponible-dark: #9eeec3;

// $project-fondeado-light: #3498db;
// $project-fondeado-dark: #2980b9;

$project-fondeado-light: #FFAD79;
$project-fondeado-dark: #FFAD79;

$compra: #1d54fa;
$purple: #4153f4;
$dark_purple: #3541ae;

$reventa_blue: #0E41DB;

$bubble_background: #fff;

$weseed-font: nunito-regular;
$weseed-font-bold: nunito-bold;
$Yellowseed: #ffff6f;
$Blueseed: #003e52;
$Blueseed-gray: #404040;
$grey_row: #f8f8f8;
$shadow-color: rgba(0, 0, 0, 0.217);
$Purpleseed: #c98bff;
$Greenseed: #9eeec3;
$GreenSold: #73e892;
$whiteSeed: #fff;
$OrangeSeed: #FFAD79;
$PurpleseedText:#13072E;
$Purpleseedbuttton:#1f0d47;
$PurplePrimario:#7C3DFF;
$Grayseed:#d4d4d4;


