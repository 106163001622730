@import "../../../styles/mixins";
@import "../../../styles/vars";

.logo_topbar {
  justify-content: center;
  width: 100%;
  padding: 5px;
  //filter: brightness(0) saturate(100%) invert(27%) sepia(44%) saturate(5522%) hue-rotate(250deg) brightness(99%) contrast(104%);
  filter: brightness(0) saturate(100%) invert(8%) sepia(21%) saturate(3621%) hue-rotate(233deg) brightness(94%) contrast(109%);
}

.topbar_mobile {
  align-items: center;
  background-color: $whiteSeed;
  display: flex;
  height: 70px;
  justify-content: space-between;
  min-width: 100vw;
  padding: 0.5em;
}
.signout_btn {
  color: $PurpleseedText;
  text-align: right;

  button {
    background: none;
    border: none;
    color: $PurpleseedText;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
  }
}
.topbar_profile_name {
  text-transform: uppercase;
}
.topbar_line_flex {
  display: flex;
  flex-direction: row;
}

.menu_items_left {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-inline-start: 1vw;

  > a {
    display: flex;
    width: 6em;

    @include desktop {
      width: 11em;
    }
  }
  @include tablet {
    margin-inline-start: 2vw;
  }
}

.menu_items_right {
  align-items: center;
  display: flex;
  font-family: $weseed-font;
  font-weight: normal;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.menu_item {
  color: $PurpleseedText;
  font-family: $weseed-font;

  .relative {
    display: inline-block;
    position: relative;
  }
}
//Change for reusable component
.btn_register {
  align-items: center;
  background: $PurplePrimario;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-family: $weseed-font;
  font-size: 1rem;
  font-weight: 600;
  height: 30px;
  justify-content: center;
  margin-inline-end: 5px;
  padding-inline: 2px;
  width: 98px;

  img {
    //background-color: $PurplePrimario;
    margin-inline-end: 4px;
    width: 13px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7492%) hue-rotate(210deg) brightness(107%) contrast(100%);
  }
  .btn_register_text {
    color: white;
  }
}
//Change for reusable component
.btn_login {
  align-items: center;
  border-radius: 5px;
  border: 1.5px solid $Blueseed;
  color: $Blueseed;
  cursor: pointer;
  display: flex;
  font-family: $weseed-font;
  font-size: 1rem;
  font-weight: 600;
  height: 30px;
  justify-content: space-evenly;
  margin-inline-end: 3px;
  padding-inline: 2px;
  width: 94px;
  margin-block: 10px;
  img {
    width: 10px;
  }
  span {
    color: $Blueseed;
  }

  a{
    @include phone{
      color: $Blueseed;
    }
  }


}
.link_grey {
  color: #343433;
}
.inverted {
  background: white;
  color: #4153f4;
}
.selected_item {
  background: #0a182e;
  height: 1px;
  width: 100%;
}
.menu_icon {
  cursor: pointer;

  img {
    height: 40px;
  }
}
.topbar_desktop {
  display: none;
}
.mobile_menu {
  align-items: center;
  background: $whiteSeed;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 1px solid $whiteSeed;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 3.5em;
  width: 100%;
}
.mobile_menu_item {
  border-bottom: 1px solid $whiteSeed;
  color: #343433;
  display: block;
  height: 6rem;
  padding: 1.3em 1.5em;
  text-align: center;
  width: 100vw;
}

.conocer_menu {
  position: absolute;
  background-color: $whiteSeed;
  top: 3.3rem;
  padding: 1rem;
  width: 38rem;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  left: -13em;
  box-shadow: 0 7px 8px 0 rgb(134 132 132 / 20%);
  .title {
    font-size: 1.2rem;
    margin-block-end: 1rem;
  }
  .question {
    font-size: 0.9rem;
  }
}

.conocer_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 600;
  color: black;
}

.conocer_item {
  font-size: 0.7rem;
  color: #343433;
}

.statusInfo {
  text-transform:none;
  font-size: 12px;
  border-radius: 8px;
  margin-left: 1em;
  padding: 5px;
}

.indocumentado {
  color: orange;
  border: 2px solid orange;
}

.validacion {
  color: #09b2d6;
  border: 2px solid #09b2d6;
}

.aprobado {
  color: #c98bff;
  border: 2px solid #c98bff;
}

.notification {
  cursor: pointer;
  margin-left: 10px;
  border-radius: 50%;
  padding: 5px;
  background-color: $PurplePrimario;
}

.notification_container {
  height: 90vh;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio (height/width) */

  @include tablet {
      margin: 0 6em;
  }

  iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
}

.notification_icon {
  height: 20px;
  width: 20px;
  filter: brightness(0) saturate(100%) invert(85%) sepia(74%) saturate(0%) hue-rotate(218deg) brightness(103%) contrast(101%);
}

@include tablet {
  .topbar_mobile {
    display: none;
  }
  .topbar_desktop {
    align-items: center;
    background-color: $whiteSeed;
    justify-content: space-between;
    display: flex;
    height: 65px;
    padding: 10px;
  }
  .menu_item {
    font-size: 0.95rem;
    margin-inline-start: 2vw;

    &.last_menu_item {
      margin-inline-end: 1vw;
    }
  }
  .nuevas_oportunidaddes{
    font-size: 0.95rem;
  }
}
@include desktop {
  .menu_item {
    font-size: calc(0.15rem + 0.8vw);
    display: inline-block;
  }
  .nuevas_oportunidaddes{
    font-size: calc(0.15rem + 0.8vw);
    display: inline-block;
  }
  .btn_login,
  .btn_register {
    font-size: 1.5rem;
    height: 38px;
    margin-inline-end: 15px;
    width: 130px;

    img {
      width: 20px;
    }
  }
  .topbar_desktop {
    justify-content: space-between;
    padding-top: 1rem;
    height: 7.5rem;
  }
  .menu_items_left {
    margin-inline-start: 3vw;
  }
  .menu_items_right {
    margin-inline-end: 3vw;
  }
}

.icon_register{
  background-color: yellow;
}
.nuevas_oportunidaddes{
  border: solid 1px;
  border-radius: 5px;
  padding: 5px;
  border-color: $PurplePrimario;
  color: $PurplePrimario;
  font-family: $weseed-font;
  font-weight: 600;
  margin-right: 20px;
}
.nuevas_oportunidaddes:hover{
  color: $whiteSeed;
  background-color: $PurplePrimario;
}
