@import '../../../styles/mixins';
@import '../../../styles/vars';

.container {
    font-size: $fontSizeS;
    margin-block-start: 2rem;
}

.name {
    color: $Blueseed;
    font-weight: bold;
    margin: $marginBlock;
    text-align: center;
}

.form {
    @include alignCenter;
}

.steps_container {
    display: flex;
    justify-content: space-around;
    margin: $marginBlock 0;
    position: relative;
    width: 100%;
    .line {
        border: 2px solid darken($grayBackgroundContainer, 10%);
        position: absolute;
        top: 35%;
        width: 75%;
        z-index: -1;
    }
 }

.buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.icono_arrow {
    width: 30px;
}

.btn_adelante, .btn_atras {
    background-color: $Purpleseed;
    border-radius: $borderRounded;
    border: none;
    color: white;
    padding: 0 $paddingContainerMin;
}