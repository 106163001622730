@import '../../../styles/mixins';
@import '../../../styles/vars';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: $marginBlock;
    width: 100%;
}

.left_content {
    .imagen_inversionista {
        height: 100px;
        width: 100px;
    }
    .codigo_title {
        color: $Purpleseed;
        font-weight: bold;
        &:hover {
            cursor: pointer;
        }
    }
}

.right_content {
    @include alignCenter;
    align-items: flex-start;
    .custom_input {
        @include inputNuevoFlujo;
    }

    .custom_input::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
    
    .custom_input:focus {
        outline: 1px solid $Purpleseed;
    }
    .personal_information {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }
    .personal_information, .residencia, .estado_civil {
        margin: $marginBlock 0;
    }
    .question_title {
        color: $Blueseed;
        font-weight: bold;
    }
    .residencia, .residencia_item, .estado_civil_item {
        align-items: center;
        display: flex;
        gap: 10px;  
    }
    .estado_civil {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }
    .residencia_item {
        align-items: center;
    }
    .direccion {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: $marginBlock;
    }
    .personal_information_item, .direccion_item {
        display: flex;
        flex-direction: column;
        label {
            color: $Blueseed;
            font-weight: bold;
        }
    }
}

@include desktop {
    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: $marginBlockSection;
    }
    .left_content {
        .imagen_inversionista {
            height: 200px;
            width: 200px;
        }
    }
    .right_content {
        .personal_information {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
        .estado_civil {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }
        .direccion {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }
    
}
