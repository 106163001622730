@import '../../../styles/mixins';
@import '../../../styles/vars';

.container {
    color: $Blueseed;
    display: grid;
    font-size: $fontSizeXs;
    grid-template-columns: repeat(1, 1fr);
    height: 95%;
    margin-block-start: 2rem;
    place-items: center;
    position: relative;
}

.loading {
    @include alignCenter;
    background-color: rgba(255, 255, 255, .9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0%;
    width: 100%;
    z-index: 100;
    .text_loading {
        font-weight: bold;
    }
}

.left_content {
    .left_image {
        height: auto;
        width: 200px;
    }
}

.right_content {
    .title {
        font-weight: bold;
        text-align: center;
    }
    .documents {
        margin: $marginBlockSection 0;
    }
    .document {
        display: flex;
        justify-content: space-between;
        margin: $marginBlock 0;
    }
    .declara_renta, .declara_renta_item {
        display: flex;
        gap: 10px;
    }
    .btn_container {
        display: flex;
        justify-content: end;
        .btn_start {
            background-color: gray;
            border-radius: $borderRounded;
            border: none;
            color: white;
            cursor: pointer;
            padding: 0 20px;
        }
        .btn_start:disabled {
            background-color: darken($grayBackgroundContainer, 5%);
        }
    }
    .select select {
        @include inputNuevoFlujo;
        appearance: none;
        width: 90%;
    }
      
      .select select:focus {
        outline: 1px solid $Purpleseed;
      }
}

@include desktop {
    .container {
        display: grid;
        font-size: $fontSizeS;
        grid-template-columns: repeat(2, 1fr);
    }
    .left_content {
        .left_image {
            height: auto;
            width: 600px;
        }
    }
    .right_content {
        .title {
            text-align: left;
        }
    }
}