@import '../../../styles/mixins';
@import '../../../styles/vars';

.container {
    margin: $marginBlockSection;
}

.pregunta {
    display: flex;
    justify-content: space-between;
    margin: $marginBlock 0;
}

.respuestas {
    display: flex;
    gap: 10px;
}

.custom_input {
    @include inputNuevoFlujo;
}

.names {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

@include desktop {
    .names {
        display: flex;
        margin: $marginBlock 0;
    }
}