$phone-width: 0px;
$tablet-width: 601px;
$desktop-width: 1024px;

@mixin tablet {
    @media (min-width: $tablet-width) {
        @content;
    }
}

@mixin phone {
    @media (min-width: $phone-width) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-width) {
        @content;
    }
}

@mixin centered_image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

@mixin button($bg: "", $border: transparent, $color: #fff, $width: auto) {
    background: $bg;
    border: 2px solid $border;
    color: $color;
    width: $width;

    &:hover {
        background: darken($bg, 8%);
        transition: all 0.3s ease;
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%);
    }

    &:active {
        background: darken($bg, 20%);
    }
}

@mixin imageCover {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

//GRID MIXINS

@mixin gridContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @include tablet {
        grid-template-columns: repeat(2, 1fr);
    }
}

@mixin gridContainer3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

@mixin gridContainer12 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

//SIZES MIXINS

@mixin calculatorContainers {
    background-color: $grayBackgroundContainer;
    border-radius: $borderRounded;
    font-size: $fontSizeXs;
    padding: $paddingContainerMin;
}

@mixin marginY {
    margin: $marginBlock 0;
}

@mixin marginB {
    margin: 0 0 $marginBlock 0;
}

@mixin table {
    tr {
        @include calculatorContainers;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
}

@mixin inputRangeThumb {
    appearance: none;
    border-radius: 50%;
    cursor: pointer;
    height: 15px;
    width: 15px;
}

@mixin purpleBubble {
    background-color: $Purpleseed;
    border-radius: $borderRounded;
    color: $whiteSeed;
    font-size: $fontSizeXxs;
    margin: 0 5px;
    padding: 5px;

}

@mixin flexCenter {
    display: flex;
    align-items: center;
    font-size: $fontSizeXs;
}

@mixin alignCenter {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

@mixin bubleIndicator {
    border-radius: 50%;
    height: 15px;
    margin: 0 5px;
    width: 15px;
}

@mixin invertirBtn {
    background-color: $Blueseed;
    border-radius: 10px;
    color: $Yellowseed;
    font-size: 16px;
    height: 3em;
    margin: $marginBlock auto 0;
    width: 60%;
    z-index: 1;
    cursor: pointer;
    border: 0;
    &:hover {
        background-color: #6d7cff;
        transition: all .3s ease-in-out;
    }
}

@mixin inputNuevoFlujo {
    background-color: darken($grayBackgroundContainer, 5%);
    border-radius: $borderRounded;
    border: none;
    padding: 5px;
    width: 100%;
}

@mixin tablasDashboard {
    width: 100%;
    font-size: $fontSizeXs;
    text-align: center;
    border-collapse: separate;
    thead {
        background-color: $grayBackgroundContainer;
    }
    th {
        background-color: $Purpleseed;
        color: white;
        font-weight: bold;
    }
    td, th {
        padding: 5px 0px;
        border: 1px solid #e0e1e5;
        border-radius: $borderRounded;
    }
    .btn_vermas {
        border: 0;
        width: 50%;
        background-color: $Purpleseed;
        color: white;
        border-radius: $borderRounded;
    }
    .btn_vermas:hover {
        background-color: $Yellowseed;
        color: $Blueseed;
        cursor: pointer;
        transition: all ease-in-out .3s;
    }
}

@mixin modalDetallesDashboard {
    .title {
        color: $PurpleseedText;
        font-weight: bold;
        text-align: center;
    }
    
    .detalles_info {
        display: flex;
        flex-direction: column;
        margin: $marginBlock 0;
    }
    
    .detalles_info_item {
        align-items: center;
        background-color: $grayBackgroundContainer;
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
        padding: 5px;
        border-radius: $borderRounded;
    }

    .subtitle {
        color: $PurpleseedText;
        font-weight: bold;
    }
}