@import "../../../styles/mixins";
@import "../../../styles/vars";

.container {
    background-color: white;
    margin-top: 70px;
    padding: 0px;
    width: 100%;
    height: 100%;
}

.wrapper {
    align-items: center;
    background-color: $grayBackgroundContainer;
    border-radius: $borderRounded;
    box-shadow: 6px 6px 15px 0px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 2rem;
    width: 95%;
}

.inputs {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    width: 100%;
    input[type=text]::-webkit-inner-spin-button,
    input[type=text]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.input {
    border-radius: $borderRounded;
    border: 1px solid $Purpleseed;
    color: black;
    font-size: 2rem;
    height: 50px;
    text-align: center;
    touch-action: auto !important;
    width: 40px;
}

.image {
    width: 120px;
}

.entry_title, .title, .subtitle {
    text-align: center;
}

.title {
    color: $Blueseed;
    font-size: $fontSizeXL;
    font-weight: bold;
    margin: 10px 0;
}

.subtitle {
    margin: 0 30px;
}

.button {
    background-color: $Purpleseed;
    border-radius: $borderRounded;
    border: 0;
    color: white;
    cursor: pointer;
    font-size: $fontSizeS;
    font-weight: bold;
    padding: 10px;
}

.button:hover {
    background-color: $Yellowseed;
    color: $Blueseed;
    transition: all ease .3s;
}

@include desktop {
    .container {
        margin-top: 70px;
        padding: 100px;
    }
    .wrapper {
        width: 50%;
    }
}