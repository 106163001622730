@import '../../../styles/mixins';
@import '../../../styles/vars';

.step {
    align-items: center;
    background-color: darken($grayBackgroundContainer, 10%);
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: $fontSizeS;
    font-weight: bold;
    height: 50px;
    justify-content: center;
    padding: $paddingContainerMin;
    width: 50px;
}

.step_container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}