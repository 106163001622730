@import '../../styles/vars';
@import '../../styles/mixins';

.content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100%;
    .image {
        height: 400px;
        width: 600px;
    }
    .text {
        color: $Blueseed;
        font-size: $fontSizeM;
        font-weight: bold;
        margin: $marginBlockSection;
        text-align: center;
    }
    .wpp {
        color: $Purpleseed;
    }

    .double_col{
        width: 100%;
        @include tablet {
            padding-top: 25em;
        }
    }

    .step{
        color:#0A182E;
        font-size: 2.5rem;
        margin-block: 2rem;
        text-align: center;
        font-weight: 600;
        @include tablet {
            font-size: 3.3rem;
            text-align: left;
            margin-inline-start: 5vw;
        }
    }

    .separator_col {
        border: 2px solid #E7EAED;
        background-color: #E7EAED;
        width: 82%;
        border-radius: 5px;
    }

    .iframe_container {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%; /* 16:9 aspect ratio (height/width) */

        @include tablet {
            margin: 0 6em;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}