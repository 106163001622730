@keyframes load {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
.loading {
  width: 100vw;
  height: 200px;
  display: flex;
  overflow: hidden;
  background: transparent;
  justify-content: center;
}

.load {
  width: 200px;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  .text {
    position: absolute;
    height: 200px;
  }
  .loader {
    position: absolute;
    animation: load 1s linear infinite;
    width: 100px;
    height: 100px;
    top: 50px;
    left: 50px;
    border-radius: 50%;
    box-shadow: 0 4px 0 0 #0a182e;
    transform-origin: 50px 52px;
  }
}
