@import "../../../styles/mixins";
@import "../../../styles/vars";

#footer {
    background: #333;
    color: #fff;
    
    background: #0e0f10;
    color: #999;
    padding: 80px 0 75px;
    // height: 700px !important;
    min-height: 200px;

    .content {
        margin: auto 60px;

        h2 {
            color: #eee;
            font-family: $weseed-font;
            font-size: 16px;
            font-weight: 700;
            text-transform: BRAND_UP;
        }


    }

    .footer-content {
        margin: auto 160px;
    }
}


.footer_item{
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  a {
    color: inherit !important;
  }
}

.company_info{
  font-size: 12px;
  margin-left: 1.35em;
  color: white;
  font-weight: 600;
  font-family: $weseed-font;
}

.footer_item_title{
  color: white;
  font-weight: 600;
  font-size: 21px;
  font-family: $weseed-font;
}

.footer_item_content{
  font-family: $weseed-font;
  text-align: justify;
  margin: 0 auto;
  margin-bottom: 8px;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.4rem;
}

.f_icon{
  //color: #112445;
  color: white;
  font-size: 18px;
}

.footer__about {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(319px, 1fr));
  gap: 10px;
}

.footer__about--column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email_footer{
  margin-bottom: 2em;
  width: 100%;
}

.footer_txt{
  font-weight: bold;
  margin-bottom: 8px;
}

.footer__logo {
    text-align: center;

}

.footer__redes {
    display: flex;
    align-items: center;
    height: 64px;
    margin-block: 10px;
}

.footer__logo--img {
  width: 10em;
  height: 3.6em;
  margin: 1em;
  margin-bottom: 5px;
}

.title-container{
  margin-block-start: 40px;
}

.partners__title{
  font-size: 2.6rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  font-family: $weseed-font;
}

.partners__items{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  gap: 7px;
  text-align: center;
  align-items: start;
  width: 90%;
}

.container__logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.superintendencia{
  padding-bottom: 3em;
  font-size: 16px;
  margin: 0 auto;
  width: fit-content;
  text-align: center;
}

.super_link{
  color: white;
  text-decoration: underline;
  font-family: $weseed-font;
}

.partner{
  width: 10em;
  height: 10em;
  margin-left: 10px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: 50% 50%;
}

.footer_flx{
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  width: 100%;
}

.footer__info{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.redes{
  width: 2.5em;
  margin: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
}

.footer_container{
  background: linear-gradient(0.25turn, #13072e, #632fd0, #13072e);
  color: white;
  padding-top: 2em;
  min-width: 100%;
}

@include tablet {
  .footer_item_content{
    font-size: 1.6rem;
  }
}