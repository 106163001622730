@import '../../../styles/mixins';
@import '../../../styles/vars';

.container {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: $marginBlockSection;
    width: 100%;
}
  
select {
  @include inputNuevoFlujo;
  appearance: none;
  width: 90%;
}

select:focus, .custom_input:focus {
  outline: 1px solid $Purpleseed;
}

.item {
    margin: $marginBlock;
}

.item, .select {
    display: flex;
    flex-direction: column;
    label {
        color: $Blueseed;
        font-weight: bold;
    }
}

.custom_input {
    @include inputNuevoFlujo;
}

.title_field {
    color: $Blueseed;
    font-weight: bold;
}

.resolucion {
    align-items: center;
    display: flex;
    gap: 10px;
    margin-bottom: $marginBlock;
    p {
        color: $Blueseed;
        font-weight: bold;
    }
}

@include desktop {
    .container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}