@import './mixins';
@import './vars';
@import '~antd/dist/antd.css';

@font-face {
  font-family: 'Proxima-Nova-Font';
  src: url(./Proxima-Nova-Font.otf) format('opentype');
}

@font-face {
  font-family: 'proxima-nova-bold';
  src: url(./proxima-nova-bold.otf) format('opentype');
}
@font-face{
  font-family: 'nunito-regular';
  src: url(./nunito/nunito-regular.ttf) format('truetype');
}

@font-face{
  font-family: 'nunito-light';
  src: url(./nunito/nunito-light.ttf) format('truetype');
}

@font-face{
  font-family: 'nunito-bold';
  src: url(./nunito/nunito-bold.ttf) format('truetype');
}

@font-face{
  font-family: 'nunito-italic';
  src: url(./nunito/nunito-italic.ttf) format('truetype');
}

header {
  position: relative;
  z-index: 1;
  width: 100vw;

  &.sticky {
    position: fixed;
    top: 0;
    z-index: 3;
  }

  .logo-editor {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 130px;
    height: 28px;
    position: absolute;
    top: 7px;
    left: 0px;
  }

  .logo-dashboard {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 130px;
    height: 28px;
    position: absolute;
    top: 7px;
    right: 90px;
  }
}


.content {
  position: relative;
}

// Format links to have the appearence of a button
// Cta
.linkButton {
  background: #0070cc;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.5s ease;
  &:hover {
    background: #005499;
    color: #fff;
  }
}

.btnLink {
  color: #0070cc;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    color: #005499;
  }
}

.dangerZone {
  background: #ffeee6;
  border: 1px solid #ffcccc;
  padding: 20px;
}

#dashboardLandscape {
  // overflow-x: hidden;
  overflow-y: hidden;
}

.ib-container {
  transition: all 0.2s ease-in-out;
}

.ant-imoblo {
  background: #ffd130 !important;
  border-color: #efbf3b !important;
}

.ant-document-faltante {
    border-color: #ccc !important;
    width: 145px;
}

.ant-document-validacion {
    display: inline-block;
    background: $Yellowseed !important;
    border-color: $Yellowseed !important;
    width: 145px;
    height: 30px;
    text-align: center;
    padding: 5px 15px;
    border-radius: 4px;
}

.ant-document-aprobado {
    display: inline-block;
    background: $Greenseed !important;
    border-color: $Greenseed !important;
    width: 145px;
    height: 30px;
    text-align: center;
    padding: 5px 15px;
    border-radius: 4px;
}

html, body { height: 100%;   width: 100%; margin: 0; padding: 0;     font-family: 'nunito-regular';  }

/* icons */

.iconMarker {
  
  height: 26px;
  width: 26px;

  @include phone {
    height: 36px;
    width: 36px;
  }
}

/* common */

.btn-block {
  width: 100%;
  display: block;
}

.strong {
  font-weight: 800;
  color: #253a47;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;

  &::before, &::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
    z-index: 0;
  }

  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: $Blueseed;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: BRAND_UP;
    text-align: center;
    z-index: 1;
  }
  /* top left */
  &.ribbon-top-left {
    top: -10px;
    left: -10px;

    &::after, &::before {
      border-top-color: transparent;
      border-left-color: transparent;
    }
    &::before {
      top: 0;
      right: 0;
    }
    &::after {
      bottom: 0;
      left: 0;
    }
    span {
      right: -25px;
      top: 30px;
      transform: rotate(-45deg);
    }
  }

  /* top right */
  &.ribbon-top-right {
    top: -10px;
    right: -10px;

    &::after, &::before {
      border-top-color: transparent;
      border-right-color: transparent;
    }
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
      right: 0;
    }
    span {
      left: -25px;
      top: 30px;
      transform: rotate(45deg);

      @include phone {
        top: 35px;
      }
    }
  }

  /* bottom left */
  &.ribbon-bottom-left {
    bottom: -10px;
    left: -10px;

    &::after, &::before {
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
    &::before {
      bottom: 0;
      right: 0;
    }
    &::after {
      top: 0;
      left: 0;
    }
    span {
      right: -25px;
      bottom: 30px;
      transform: rotate(225deg);
    }
  }

  /* bottom right */
  &.ribbon-bottom-right {
    bottom: -10px;
    right: -10px;

    &::after, &::before {
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
    &::before {
      bottom: 0;
      left: 0;
    }
    &::after {
      top: 0;
      right: 0;
    }
    span {
      left: -25px;
      bottom: 30px;
      transform: rotate(-225deg);
    }
  }
}

::-webkit-scrollbar {
  width: 9px;
  height: 7px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #838383;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0A182E;
}