@import '../../../styles/mixins';
@import '../../../styles/vars';

.container {
    margin: $marginBlock;
}

.custom_input {
    @include inputNuevoFlujo;
}

.custom_input:focus {
    outline: 1px solid $Purpleseed;
}

.question_title, .origen_fondos {
    color: $Blueseed;
    font-weight: bold;
    margin: $marginBlock 0;
    text-align: left;
}

.declaracion {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.estado_declaracion {
    border-radius: $borderRounded;
    border: 1px solid $Purpleseed;
    color: $Purpleseed;
    padding: 0 20px;
}

.origen_fondos {
    cursor: pointer;
    text-decoration: underline;
}

.declaracion, .declaracion_item, .recursos {
    display: flex;
    gap: 10px;
}

.recursos {
    flex-direction: column;
}

.finanzas {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
}

.finanzas_item {
    display: flex;
    flex-direction: column;
    label {
        color: $Blueseed;
        font-weight: bold;
    }
}

@include desktop {
    .container {
        margin: $marginBlockSection;
    }
    .finanzas {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}