.isActive {
    background-color: #c98bff;
}

.check {
    background-color: #003e52;
}

.startForms {
    background-color: #c98bff !important;

}

.statusDeclaracionTrue {
    background-color: #9eeec3;
    border: 1px solid #9eeec3 !important;
    color: #003e52 !important;
}